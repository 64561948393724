import React from "react";
import { Route, BrowserRouter, Routes, HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./configureStore";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DisplayContextProvider } from "./DisplayContext";
import { ApolloProvider } from "./ApolloProvider";
import * as Pages from "../pages";
import * as Components from "../components";
import { ClerkProvider } from "@clerk/clerk-react";
import * as styles from "./App.style";

// Create Redux Store
const store = getStore();

// Handle Routing
const Page: React.FC = () => {
  return (
    <div className={styles.pageContainer}>
      <Routes>
        <Route path="/" element={<Pages.Home />} />
        <Route path="/users" element={<Pages.Users />} />
        <Route path="/contests" element={<Pages.ContestTemplates />} />
        <Route path="/rewards" element={<Pages.Rewards />} />
        <Route path="/prizes" element={<Pages.Prizes />} />
        <Route path="/terms" element={<Pages.Terms />} />
      </Routes>
    </div>
  );
};

const PUBLISHABLE_KEY = process.env.CLERK_PUBLISHABLE_KEY ?? "";

export function App() {
  return (
    <ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="/">
      <ApolloProvider>
        <Provider store={store}>
          <HashRouter>
            <DisplayContextProvider>
              <Components.MobileHeader />
              <div className={styles.app}>
                <Components.Header />
                <Page />
              </div>
            </DisplayContextProvider>
            <ToastContainer
              autoClose={5000}
              position="top-right"
              newestOnTop={false}
              hideProgressBar={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              pauseOnHover
            />
          </HashRouter>
        </Provider>
      </ApolloProvider>
    </ClerkProvider>
  );
}
